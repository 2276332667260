import { Injectable } from "@angular/core";
import { EpisodePreEvaluation } from "../shared/services/models/telemedicine/episode-pre-evaluation.model";
import { TelemedicineConfigStruct } from "../shared/services/structs/telemedicine/telemedicine-config.struct";
import { SelfTriageConfigStruct } from "../shared/services/structs/self-triage/self-triage-config.struct";

@Injectable({
    providedIn: 'root'
})
export class UtilsTelemedicineService {
    constructor() { }

    updateEpisodePreEvaluation(episodePreEvaluation: EpisodePreEvaluation) {
        sessionStorage.setItem("episodePreEvaluation", JSON.stringify(episodePreEvaluation));
    }

    removeEpisodePreEvaluation() {
        sessionStorage.removeItem("EpisodePreEvaluation");
    }

    getEpisodePreEvaluation() {
        let episodePreEvaluation: EpisodePreEvaluation = JSON.parse(sessionStorage.getItem("episodePreEvaluation"))
        return episodePreEvaluation;
    }

    createEpisodePreEvaluation(episodePreEvaluation: EpisodePreEvaluation) {
        sessionStorage.setItem("episodePreEvaluation", JSON.stringify(episodePreEvaluation));
    }

    updatePatientData(patientData: PatientData) {
        sessionStorage.setItem("patientData", JSON.stringify(patientData));
    }

    removePatientData() {
        sessionStorage.removeItem("patientData");
    }

    getPatientData() {
        let dataString = sessionStorage.getItem("patientData")
        if (dataString != undefined) {
            let patientData: PatientData = JSON.parse(dataString);
            return patientData;
        }

        return null;
    }

    createEpisodeData(episodeData: EpisodeData) {
        sessionStorage.setItem("episodeData", JSON.stringify(episodeData));
    }

    setSelfTriageAcessTelemedicine(data: boolean): void {
        sessionStorage.setItem("selfTriageAccessTelemedicine", JSON.stringify(data));
    }

    getSelfTriageAcessTelemedicine(): boolean {
        const data: string = sessionStorage.getItem("selfTriageAccessTelemedicine");
        return data ? JSON.parse(data) : false
    }

    setCelerusAcessTelemedicine(data: boolean): void {
        sessionStorage.setItem("celerusAccessTelemedicine", JSON.stringify(data));
    }

    getCelerusAcessTelemedicine(): boolean {
        const data: string = sessionStorage.getItem("celerusAccessTelemedicine");
        return data ? JSON.parse(data) : false
    }

    setTelephoneClassificationTelemedicine(data: boolean): void {
        sessionStorage.setItem("telephoneClassificationAccessTelemedicine", JSON.stringify(data));
    }

    getTelephoneClassificationTelemedicine(): boolean {
        const data: string = sessionStorage.getItem("telephoneClassificationAccessTelemedicine");
        return data ? JSON.parse(data) : false
    }

    getEpisodeData() {
        let dataString = sessionStorage.getItem("episodeData")
        if (dataString != undefined) {
            let episodeData: EpisodeData = JSON.parse(dataString);
            return episodeData;
        }
        return null;
    }

    removeEpisodeData() {
        sessionStorage.removeItem("episodeData");
    }

    updateVideoCallData(videoCallData: VideoCallData) {
        sessionStorage.setItem("videoCallData", JSON.stringify(videoCallData));
    }

    removeVideoCallData() {
        sessionStorage.removeItem("videoCallData");
    }

    getVideoCallData() {
        let videoCallData: VideoCallData = JSON.parse(sessionStorage.getItem("videoCallData"));
        return videoCallData;
    }

    updateUri(uri: string) {
        sessionStorage.setItem("uri", uri);
    }

    removeUri() {
        sessionStorage.removeItem("uri");
    }

    getUri() {
        let uri: string = sessionStorage.getItem("uri");
        return uri;
    }

    setTelemedicineConfig(data: TelemedicineConfigStruct): void {
        sessionStorage.setItem("telemedicineConfig", JSON.stringify(data));
    }

    getTelemdicineConfig(): TelemedicineConfigStruct {
        const data: string = sessionStorage.getItem("telemedicineConfig");
        return data ? JSON.parse(data) : null
    }

    clearTelemdicineConfig(): void {
        sessionStorage.removeItem("telemedicineConfig");
    }

    setPreview(data: boolean): void {
        sessionStorage.setItem("preview", JSON.stringify(data));
    }

    setOldFlowControl(data: number): void {
        sessionStorage.setItem("oldFlowControl", JSON.stringify(data));
    }

    getOldFlowControl(): number | null {
        const storedData = sessionStorage.getItem("oldFlowControl");

        if (storedData !== null)
            return JSON.parse(storedData);

        return null;
    }

    removeOldFlowControl() {
        sessionStorage.removeItem("oldFlowControl");
    }

    getPreview(): boolean {
        const data: string = sessionStorage.getItem("preview");
        return data ? JSON.parse(data) : false
    }

    setForwardMedic(data: boolean): void {
        sessionStorage.setItem("forwardMedic", JSON.stringify(data));
    }

    getForwardMedic(): boolean {
        const data: string = sessionStorage.getItem("forwardMedic");
        return data ? JSON.parse(data) : false
    }

    clearPreview(): void {
        sessionStorage.removeItem("preview");
    }
}

export class PatientData {
    public idPatient: number;
    public idHealthUnit: number;
    public patientName: string;
    public cpf: string;
    public phone: string;
    public email: string;
    public birthDate: Date;
    public idGender: number;
    public state: string;
    public city: string;
    public zipCode: string;
    public neighborhood: string;
    public street: string;
    public houseNumber: number;
    public cns: string;
    public healthPlan: string;
    public healthPlanNumber: string;
    public healthPlanExpirationDate: Date;
    public datetimeinclusion: Date;
    public idQueue: number;
    public patientCenterAccessTelemedicine: boolean;
    public idPatientTelemedicineIntegration:number;
}

export class EpisodeData {
    public idQueue: number;
    public idEpisode: number;
    public actualTicket: string;
    public lastTicket: string;
    public waitingTime: string;
}

export class VideoCallData {
    public videoToken: string;
    public idEpisode: number;
    public idAttendantUser: number;
    public attendantUserName: string;
    public roomName: string;
}