<div class="main-container">
    <app-header *ngIf="!isMobileVersion"></app-header>
    <div [ngClass]="{'container-background':!isMobileVersion}" [ngStyle]="{'background-color': (colorCode && !isMobileVersion)? colorCode : null}" *ngIf="!isFinished">
        <div [ngClass]="{'body-content-video-call-not-mobile':!isMobileVersion}">
            <div [ngClass]="{'client-body-mobile':isMobileVersion, 'white-container-attend-video':!isMobileVersion}">
                <span *ngIf="isMobileVersion" class="doc-name-mobile" [ngStyle]="{'color': colorCode}">Dr(a):
                    {{this.appointmentData?.attendantUserName}}
                </span>
                <div [ngClass]="{'video-container-mobile':isMobileVersion,'video-container':!isMobileVersion}">
                    <app-zoom-video #zoomVideo
                        (leaveRoom)="onLeaveRoom($event)" 
                        [userName]="appointmentData.patientName" 
                        [token]="this.appointmentData.patientToken" 
                        [idPatientAppointment]="idPatientAppointment"
                        [sessionName]="appointmentData.roomName">
                    </app-zoom-video>
                </div>
            </div>
        </div>
        
    </div>
    <app-ended-query *ngIf="isFinished" [colorCode]="colorCode" [isMobilVersion]="isMobileVersion" (finish)="finish()"></app-ended-query>
</div>