import { Socket } from 'socket.io-client';
import * as myGlobals from 'globals'; //<==== this one (**Updated**)
import { Component } from '@angular/core';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WebsocketTelemedicineService } from '../websocket-telemedicine.service';
import { WebsocketAppointmentService } from '../websocket-appointment.service';

@Injectable({
    providedIn: 'root'
})
export class WebsocketAppointmentUtilService {

    constructor(private websocketAppointmentService: WebsocketAppointmentService, private router: Router) { }
    socket: Socket;
    idPatientAppointment: number;
    onClientAppointment: Function;
    onCountSeconds: Function;
    onCountReady: Function;
    onTimeExpired: Function;
    onEmitAction: Function;
    onVerifyParticipants: Function;
    thisParam: any;


    isConnect(): boolean {
        return this.socket && this.socket.connected;
    }

    blankFunction() {

    }

    setFunctions(onClientAppointment: Function, onCountSeconds: Function, onCountReady: Function, onTimeExpired: Function, onEmitAction: Function, onVerifyParticipants: Function) {
        this.onClientAppointment = onClientAppointment ? onClientAppointment : this.blankFunction;

        this.onCountSeconds = onCountSeconds ? onCountSeconds : this.blankFunction;

        this.onCountReady = onCountReady ? onCountReady : this.blankFunction;

        this.onTimeExpired = onTimeExpired ? onTimeExpired : this.blankFunction;

        this.onEmitAction = onEmitAction ? onEmitAction : this.blankFunction;

        this.onVerifyParticipants = onVerifyParticipants ? onVerifyParticipants : this.blankFunction;
    }

    clearSocket(): void {
        if (this.socket && this.socket.connected) {
            this.socket.disconnect();
        }
        this.socket = null;
    }

    setThisParam(thisParam: any) {
        this.thisParam = thisParam;
    }

    emitCountReady(idPatientAppointment) {
        this.socket.emit('countReady', `idPatientAppointment-${idPatientAppointment}`)
    }

    emitAction(idPatientAppointment, action) {
       this.socket.emit('triggerAction', `${action};idPatientAppointment-${idPatientAppointment}`)
    }

    emitVerifying(idPatientAppointment) {
        this.socket.emit('verifying', `idPatientAppointment-${idPatientAppointment}`)
    }

    connectwebsocketRoom(idPatientAppointment: number, thisParam: any): void {
        this.idPatientAppointment = idPatientAppointment;
        this.thisParam = thisParam;

        this.socket = this.websocketAppointmentService.websocketConnection();
        this.socket.emit('join', `idPatientAppointment-${this.idPatientAppointment}`)
            .on('clientAppointment', (res) => { this.onClientAppointment(res, this.thisParam) })
            .on('countReady', () => {this.onCountReady(this.thisParam)})
            .on('countSeconds', (seconds) => this.onCountSeconds(seconds, this.thisParam))
            .on('personsInRoom', (res) => {this.onVerifyParticipants(res, this.thisParam)})
            .on('timeExpired', (idPatientAppointment) => this.onTimeExpired(idPatientAppointment, this.thisParam))
            .io.on("reconnect", (res) => {
                this.socket.emit('join', `idPatientAppointment-${this.idPatientAppointment}`);
            });
    }
}