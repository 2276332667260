<!-- <div id='wrapperContainer'>
    <div id='controlsContainer'></div>
    <div id='sessionContainer'></div>
    <div id='chatContainer'></div>
    <div id='videoContainer'></div>
</div> -->

<div class="container">
    <div id='sessionContainer'></div>
</div>
