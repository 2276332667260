import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsSelfTriageService } from 'src/app/self-triage/utils.service';
import { WebsocketTelemedicineActionEnum } from 'src/app/shared/components/enum/websocket-telemedicine-action.enum';
import { ZoomVideoComponent } from 'src/app/shared/components/zoom-video/zoom-video.component';
import { TelemedicineFlowEnum } from 'src/app/shared/enum/telemedicine/telemedicine-flow.enum';
import { TriggerWebsocketService } from 'src/app/shared/services/API/orchestrator-telemedicine/trigger-websocket.service';
import { TelemedicineConfigStruct } from 'src/app/shared/services/structs/telemedicine/telemedicine-config.struct';
import { WebsocketTelemedicineUtilService } from 'src/app/shared/services/websocket-util/websocket-telemedicine-util.service';
import { EpisodeData, PatientData, UtilsTelemedicineService, VideoCallData } from 'src/app/telemedicine/utils.service';

@Component({
  selector: 'app-attend-patient-video',
  templateUrl: './attend-patient-video.component.html',
  styleUrls: ['./attend-patient-video.component.css']
})
export class AttendPatientVideoComponent implements OnInit, AfterViewInit {

  @ViewChild('zoomVideo') zoomVideo: ZoomVideoComponent;
  private unloadHandler: (event: BeforeUnloadEvent) => void;

  constructor(
    public utilsTelemedicineService: UtilsTelemedicineService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private router: Router,
    public dialog: MatDialog,
    private utilService: UtilsTelemedicineService,
    public triggerWebsocketService: TriggerWebsocketService,
    public websocketTelemedicineUtilService: WebsocketTelemedicineUtilService,
    private utilSelfTriageService: UtilsSelfTriageService,
  ) {
    this.unloadHandler = this.handleBeforeUnload.bind(this);
  }

  public timeout: any;
  public uri: string;
  public colorCode: string;
  public telemedicineConfig: TelemedicineConfigStruct;
  public idEpisode: number;
  public videoCallData: VideoCallData;
  public patientCallData: PatientData;
  public isMobileVersion: boolean;
  public isFinished: boolean = false;
  public isForward: boolean = false;
  public selfTriageAccessTelemedicine: boolean = false;
  public telephoneClassificationTelemedicine: boolean = false;
  public endMedicalCare: boolean = false;
  public isPatientCenter: boolean = false;
  public attendanceLocal: number = null;
  public loadVideo: boolean = false;

  ngOnInit(): void {
    window.addEventListener('beforeunload', this.unloadHandler);

    if (this.activatedRoute.snapshot.paramMap.get('uri')) {
      this.uri = this.activatedRoute.snapshot.paramMap.get('uri');
      this.telemedicineConfig = this.utilsTelemedicineService.getTelemdicineConfig();
      this.colorCode = this.telemedicineConfig.colorCode;
    }
    if (this.activatedRoute.snapshot.paramMap.get('isPatientCenter') == "true") {
      this.isPatientCenter = true;
    }

    this.isMobile();

    this.videoCallData = this.utilsTelemedicineService.getVideoCallData();
    this.patientCallData = this.utilsTelemedicineService.getPatientData();
    this.utilsTelemedicineService.setOldFlowControl(TelemedicineFlowEnum.waitingArea);

    this.idEpisode = this.utilService.getEpisodeData().idEpisode;
    if(this.videoCallData?.roomName) this.loadVideo = true;
    this.startWebsocket();
  }

  ngAfterViewInit(): void {
    if (this.videoCallData && this.videoCallData.videoToken)
      this.timeout = setTimeout(() => this.startVideo(), 1000);
  }

  handleBeforeUnload(event: BeforeUnloadEvent) {
    if (event.type === 'reload') {
      return;
    }
    else if (event.type === 'beforeunload') {
      this.utilsTelemedicineService.removeOldFlowControl();
      this.websocketTelemedicineUtilService.clearSocket();
      return this.router.navigate([`telemedicine/${this.uri}`, { warnMedicLeft: null }]);
    }
  }
  
  startVideo() {
    clearTimeout(this.timeout);
  }

  onLeaveRoom(isEvasion: boolean) {
    clearTimeout(this.timeout);
    this.videoCallData = null;
    this.utilsTelemedicineService.removeVideoCallData();
    this.isFinished = true;

    if (isEvasion) {
      this.utilsTelemedicineService.clearTelemdicineConfig();
      this.utilsTelemedicineService.removeEpisodeData();
      this.utilsTelemedicineService.removeOldFlowControl();

      if (!this.patientCallData.patientCenterAccessTelemedicine) {
        this.utilsTelemedicineService.removePatientData();
      }
        
      this.triggerAction(WebsocketTelemedicineActionEnum.patientEvasion);
    } 
    
    else if (this.isForward) {
      this.router.navigate([`telemedicine/${this.uri}/attend-patient/forward-episode`]);
    }

    else if (!this.endMedicalCare) {
      let isPreview: boolean = this.utilsTelemedicineService.getPreview();

      if (isPreview) {
        this.router.navigateByUrl(`/telemedicine/${this.uri}?preview=true`);
      }

      else if (this.utilsTelemedicineService.getSelfTriageAcessTelemedicine() && this.utilSelfTriageService.getFullUrl()) {
        this.router.navigateByUrl(`telemedicine/${this.uri}?warnMedicLeft=true`);
      }

      else if (this.isPatientCenter) {
        this.websocketTelemedicineUtilService.clearSocket();
        this.router.navigate([`telemedicine/${this.uri}/patient-center`]);
      }

      else {
        this.websocketTelemedicineUtilService.clearSocket();
        this.router.navigate([`telemedicine/${this.uri}`]);
      }
    }
  }

  //Websocket
  startWebsocket() {
    if (!this.websocketTelemedicineUtilService.isConnect()) {
      this.websocketTelemedicineUtilService.connectwebsocketRoom(this.idEpisode, this.telemedicineConfig.waitingTimePatientDoctorInSeconds, this);
    }

    this.websocketTelemedicineUtilService.setContext(this);
    this.websocketTelemedicineUtilService.setFunctions(this.verifyTrigger);

  }

  verifyTrigger(client: any) {
    if (client.action == WebsocketTelemedicineActionEnum.medicEvasion) {
      this.websocketTelemedicineUtilService.clearSocket();
      this.zoomVideo.onLeaveSession();      
      this.onLeaveRoom(false);
    } 
    
    else if (client.action == WebsocketTelemedicineActionEnum.endMedicalCare) {
      this.attendanceLocal = client.attendanceLocal;
      this.endMedicalCare = true;
      this.utilsTelemedicineService.removeEpisodeData();
      this.utilsTelemedicineService.removeOldFlowControl();

      if (!this.patientCallData.patientCenterAccessTelemedicine) {
        this.utilsTelemedicineService.removePatientData();
      }
        
      this.websocketTelemedicineUtilService.clearSocket();
      this.zoomVideo.onLeaveSession(); 
      this.onLeaveRoom(false);      
    }
    
    else if (client.action == WebsocketTelemedicineActionEnum.patientEvasion) {
      this.utilsTelemedicineService.removeEpisodeData();
      this.utilsTelemedicineService.removeOldFlowControl();

      if (!this.patientCallData.patientCenterAccessTelemedicine) {
        this.utilsTelemedicineService.removePatientData();
      }
        
      this.websocketTelemedicineUtilService.clearSocket();
      this.finish();
    }

    else if (client.action == WebsocketTelemedicineActionEnum.forward) {
      this.isForward = true;
      let episodeData: EpisodeData = this.utilsTelemedicineService.getEpisodeData();
      episodeData.idQueue = client.idQueue ? client.idQueue : null;
      this.utilsTelemedicineService.createEpisodeData(episodeData);
      this.zoomVideo.onLeaveSession();
      this.onLeaveRoom(false);
    }
  }

  finish() {
    this.selfTriageAccessTelemedicine = this.utilsTelemedicineService.getSelfTriageAcessTelemedicine();
    this.telephoneClassificationTelemedicine = this.utilsTelemedicineService.getTelephoneClassificationTelemedicine();

    if (this.patientCallData.patientCenterAccessTelemedicine || this.selfTriageAccessTelemedicine || this.telephoneClassificationTelemedicine)
      this.utilsTelemedicineService.setForwardMedic(false);
    else {
      this.utilsTelemedicineService.clearTelemdicineConfig();
      this.utilsTelemedicineService.removeEpisodeData();
      this.utilsTelemedicineService.removePatientData();
      this.utilsTelemedicineService.removeOldFlowControl();
    }

    let isPreview: boolean = this.utilsTelemedicineService.getPreview();
    if (isPreview) {
      this.router.navigateByUrl(`/telemedicine/${this.uri}?preview=true`);
    }

    else if (this.utilsTelemedicineService.getSelfTriageAcessTelemedicine() && this.utilSelfTriageService.getFullUrl()) {
      window.open(this.utilSelfTriageService.getFullUrl(), "_self");
    }
    else if (this.isPatientCenter) {
      this.router.navigate([`telemedicine/${this.uri}/patient-center`, { warnMedicLeft: null }]);
    }
    else {
      this.websocketTelemedicineUtilService.clearSocket();
      this.router.navigate([`telemedicine/${this.uri}`, { warnMedicLeft: null }]);
    }
  }

  triggerAction(action: number) {
    this.websocketTelemedicineUtilService.emitAction(action, this.idEpisode);
  }

  isMobile() {
    const userAgent: string = navigator.userAgent.toLowerCase();

    let isMobile: boolean = /iphone|android/i.test(navigator.userAgent);

    let isTablet: boolean = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);

    if (isMobile || isTablet || screen.orientation.type == "portrait-primary")
      this.isMobileVersion = true;
    else
      this.isMobileVersion = false;
  }
}